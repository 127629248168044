/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "El alto precio de los derivados de los combustibles fósiles producido en parte por la captura de estados por parte de petroleras y la crisis de escalas está provocando que cada vez más gente piense en producirse su propio biodiésel, es muy rentable y es un proceso bien conocido, si queréis más detalles os recomiendo el siguiente blog: ", React.createElement(_components.a, {
    href: "http://biodiesel-jose.blogspot.com.es/"
  }, "Elaboración casera de Biodiesel"), ", en él se explican todos los pasos con recomendaciones para construirte tu fábrica artesanal de biodiésel. El proceso utiliza aceite de cocina usado para convertirlo el diésel que se puede gastar en cualquier vehículo sin problemas."), "\n", React.createElement(_components.p, null, "Pero esto no queda aquí también es sencillo producir biogás a partir la fermentación de desechos, es lo que propone el ", React.createElement(_components.a, {
    href: "http://www.gekgasifier.com/info/gekproject",
    title: "GEK Project"
  }, "proyecto GEK"), ", una iniciativa open source que pretende producir energía a escala personal, no sólo biogás, si no todo tipo de energía."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox.entramado.net/wp-content/uploads/sites/6/aplmagicbox_v5-1024x5361.png"
  }, React.createElement(_components.img, {
    src: "http://litox.entramado.net/wp-content/uploads/sites/6/aplmagicbox_v5-1024x5361.png?w=950",
    alt: "APLmagicbox_v5-1024x536"
  }))), "\n", React.createElement(_components.p, null, "Además es posible no sólo esto también extraer etanol, que se puede añadir a la gasolina, formando lo que se conoce como gasohol."), "\n", React.createElement(_components.p, null, "El siguiente problema que se ha presentado a los productores de combustibles caseros es que materia prima utilizar, tanto en el caso de la biomasa como el del aceite, el objetivo es que sea lo mas verde posible y a la vez barato. Lo que más me ha llamado la atención es el cultivo de microalgas, que aunque aún parece que no es muy rentable se están haciendo investigaciones P2P muy interesantes, recomiendo tanto ", React.createElement(_components.a, {
    href: "http://www.oilgae.com/forum/"
  }, "el foro de Oilgae"), " en inglés como la siguiente ", React.createElement(_components.a, {
    href: "http://www.infojardin.com/foro/showthread.php?t=276742",
    title: "Cultivo de microalgas"
  }, "conversación en castellano en éste otro foro"), ". La ventaja de las microalgas es que se pueden cultivar sobre suelo no fértil y son muy productivas, pero plantea otros problemas como veréis en estos hilos."), "\n", React.createElement(_components.p, null, "Como veis es un tema del que se habla mucho, un tipo de producción P2P que está teniendo mucho éxito y espero que continúe extendiéndose y mejorando."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
